// dateReducer.js
import moment from 'moment';
import {UPDATE_MENU_DATE} from '../actions/index';

const initialState = moment().format("YYYY-MM-DD");

export default function dateReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_MENU_DATE:
            return action.payload.date;
        default:
            return state;
    }
}
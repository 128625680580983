import {IS_CHEAT_MEAL_AVAILABLE_FAILURE, IS_CHEAT_MEAL_AVAILABLE_SUCCESS} from '../actions/index';

const initialState = {
    data: {},
    loading: true,
    error: null
};

export default function cheatMealReducer(state = initialState, action) {
    switch (action.type) {
        case IS_CHEAT_MEAL_AVAILABLE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.is_cheat_meal_available
            };
        case IS_CHEAT_MEAL_AVAILABLE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: {}
            };
        default:
            return state;
    }
}
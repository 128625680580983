import {config} from '../helpers.js';

window.fbAsyncInit = function () {
    window.FB.init({
        appId: config('facebook.app_id'),
        cookie: true,
        xfbml: true,
        version: 'v5.0'
    });
};

// Load the SDK asynchronously
(function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "//connect.facebook.net/" + config('facebook.locale') + "/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));


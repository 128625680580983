import {
    FETCH_NEW_RECIPES_FAILURE,
    FETCH_NEW_RECIPES_SUCCESS,
    FETCH_POPULAR_RECIPES_FAILURE,
    FETCH_POPULAR_RECIPES_SUCCESS,
    FETCH_RECIPES_BEGIN,
    FETCH_RECIPES_FAILURE,
    FETCH_RECIPES_SUCCESS,
    SET_KEYWORD
} from '../actions/index';

const initialState = {
    data: [],
    loading: false,
    error: null,
    meta: {},
    popular_loading: true,
    popular_recipes: [],
    new_loading: true,
    new_recipes: [],
    keyword: ""
};

export default function recipesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_RECIPES_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case FETCH_RECIPES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.recipes.meta.current_page > 1 && !action.payload.recipes.reset ? [...state.data, ...action.payload.recipes.data] : action.payload.recipes.data,
                meta: action.payload.recipes.meta
            };
        case FETCH_RECIPES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: [],
                meta: {},
            };
        case FETCH_POPULAR_RECIPES_SUCCESS:
            return {
                ...state,
                popular_loading: false,
                popular_recipes: action.payload.popular_recipes
            };
        case FETCH_POPULAR_RECIPES_FAILURE:
            return {
                ...state,
                popular_loading: false,
                popular_recipes: []
            };
        case FETCH_NEW_RECIPES_SUCCESS:
            return {
                ...state,
                new_loading: false,
                new_recipes: action.payload.new_recipes
            };
        case FETCH_NEW_RECIPES_FAILURE:
            return {
                ...state,
                new_loading: false,
                new_recipes: []
            };
        case SET_KEYWORD:
            return {
                ...state,
                keyword: action.payload.keyword,
            };
        default:
            return state;
    }
}

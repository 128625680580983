// userReducer.js
import {
    FETCH_QUICK_START_FAILURE,
    FETCH_QUICK_START_SUCCESS,
} from '../actions/index';

const initialState = {
    data: [],
    total_completed: 0,
    loading: true,
    error: null,
};

export default function quickStartReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_QUICK_START_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                total_completed: action.payload.total_completed,
            };
        case FETCH_QUICK_START_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: []
            };
        default:
            return state;
    }
}
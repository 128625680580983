// userReducer.js
import {FETCH_PHYSICAL_ACTIVITIES_FAILURE, FETCH_PHYSICAL_ACTIVITIES_SUCCESS} from '../actions/index';

const initialState = {
    data: [],
    loading: true,
    error: null
};

export default function physicalActivitiesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_PHYSICAL_ACTIVITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.physical_activities
            };
        case FETCH_PHYSICAL_ACTIVITIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: []
            };
        default:
            return state;
    }
}
import config from '../config/config.js';
import {getCookie, setCookie} from './helpers.js';
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from 'i18next-http-backend';
import moment from 'moment';
import dayjs from "dayjs";

require('dayjs/locale/et');
require('dayjs/locale/fi');

var urlParams = new URLSearchParams(window.location.search);
var langCookie = getCookie('lang');
config.lang = urlParams.has('lang')
    ? urlParams.get('lang')
    : (langCookie ? langCookie : config.default_lang);
setCookie('lang', config.lang);

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: config.lang,
        fallbackLng: false,
        load: 'currentOnly',
        backend: {
            loadPath: config.api.url + 'v2/translations?language=' + config.lang,
            parse: function (data) {
                const jsonData = JSON.parse(data);
                return jsonData.data.translations;
            },
        },
        debug: false,
        returnEmptyString: false,
        returnNull: false,
        interpolation: {
            escapeValue: false,
            format: function (value, format, lng) {
                dayjs.locale(lng);
                moment.locale(lng);
                if (format == 'weekday') {
                    return moment(value).format('dd');
                }
                if (format == 'shortDate') {
                    return moment(value).format('Do MMMM');
                }
                if (format == 'longDate') {
                    return moment(value).format('dddd, Do MMMM');
                }

                return moment(value).format(format);
            }
        },
        react: {
            useSuspense: true
        },
        keySeparator: false,
    });

i18n.on('languageChanged', function (lng) {
    moment.locale(lng);
    dayjs.locale(lng);
});

export default i18n;

// userReducer.js
import {
    FETCH_SHOPPING_LIST_CATEGORIES,
    FETCH_SHOPPING_LIST_FAILURE,
    FETCH_SHOPPING_LIST_SUCCESS
} from '../actions/index';

const initialState = {
    data: {},
    loading: true,
    error: null,
    categories: []
};

export default function shoppingListReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_SHOPPING_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.shopping_list
            };
        case FETCH_SHOPPING_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: {}
            };
        case FETCH_SHOPPING_LIST_CATEGORIES:
            return {
                ...state,
                categories: action.payload.data
            };
        default:
            return state;
    }
}
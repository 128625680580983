import React from "react";
import {useSelector} from "react-redux";
import {
    level_badge_bronze,
    level_badge_diamond,
    level_badge_gold,
    level_badge_max,
    level_badge_silver
} from "../../assets.js";

const LevelBadge = ({level, type, style}) => {
    const levels = useSelector(state => state.levels.data);
    const badgeLevel = level ? level : levels.level;

    const getBadge = () => {
        if (badgeLevel < 5) {
            return level_badge_bronze;
        }

        if (badgeLevel < 10) {
            return level_badge_silver;
        }

        if (badgeLevel < 20) {
            return level_badge_gold;
        }

        if (badgeLevel < 30) {
            return level_badge_diamond;
        }

        return level_badge_max;
    }

    const getBadgeTextColor = () => {
        if (badgeLevel < 10) {
            return "#262626";
        }

        if (badgeLevel < 20) {
            return "darkorange";
        }

        return "white"
    }

    return (
        <div className={"user-level-badge " + (type ? type : "")} style={style}>
            <img src={getBadge()} alt=""/>
            <h1 style={{color: getBadgeTextColor()}}>{badgeLevel}</h1>
        </div>
    )
}

export default LevelBadge;
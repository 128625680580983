import React, {createContext, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {getNotifications, getStartGuide, getUserLevels} from "./actions";
import {config, getCookie, isEmpty} from "./helpers";
import Echo from 'laravel-echo';
import LevelUpModal from "./components/modals/level-up-modal";

const WebSocketContext = createContext(null);

window.Pusher = require('pusher-js');

export {WebSocketContext}

const getChannelPrefix = () => {
    const domain = config('domain');
    
    switch (domain) {
        case 'com':
            return 'en';
        case 'fi':
            return 'fi';
        default:
            return 'et';
    }
};

const WebSocketProvider = ({children}) => {
    const dispatch = useDispatch();
    const levels = useSelector(state => state.levels.data);
    const user = useSelector(state => state.user.data);
    const [levelUpData, setLevelUpData] = useState(false);
    const [connected, setConnected] = useState(false);
    const channelPrefix = getChannelPrefix();

    const websocket_url = config('api.websocket');
    const websocket_key = config('api.websocket_key');
    let socket;
    let ws;

    if (!connected && websocket_url) {
        const token = getCookie('fl_access_token');

        if (user.user_id && !isEmpty(levels)) {
            socket = new Echo({
                broadcaster: 'pusher',
                key: websocket_key,
                cluster: 'mt1',
                forceTLS: false,
                wsPort: 6001,
                wssPort: 6001,
                enabledTransports: ['ws', 'wss'],
                wsHost: websocket_url,
                authEndpoint : (getCookie('custom_api') ? getCookie('custom_api') : config('api.url')) + 'broadcasting/auth',
                auth: {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                },
            });

            setConnected(true);

            // Track level up
            socket.private(`${channelPrefix}.user.${user.user_id}.levels`).listen('.LevelUp', (e) => {
                setTimeout(() => {
                    setLevelUpData(e);
                }, 1000)

                dispatch(getUserLevels());
            });

            // Track points gained
            socket.private(`${channelPrefix}.user.${user.user_id}.levels`).listen('.ReceivedPoints', (e) => {
                // Only refresh on profile page
                if (window.location.href.includes('/profile')) {
                    dispatch(getUserLevels());
                }
            });

            // Track notifications
            socket.private(`${channelPrefix}.user.${user.user_id}.notifications`).listen('.Notifications', (e) => {
                //TODO: add new notification from websocket data to existing notifications
                dispatch(getNotifications());
            });


            socket.channel(`${channelPrefix}.notifications`).listen('.GlobalNotifications', (e) => {
                //TODO: add new notification from websocket data to existing notifications
                dispatch(getNotifications());
            });

            // Track notifications
            socket.private(`${channelPrefix}.user.${user.user_id}.start-guide`).listen('.startGuideCompleted', (e) => {
                console.log('Startguide Completed event recieved');
                console.log(e);
                dispatch(getStartGuide());
            });

        }

        ws = {socket: socket}
    }

    return (
        <WebSocketContext.Provider value={ws}>
            {children}

            <LevelUpModal data={levelUpData}/>
        </WebSocketContext.Provider>
    )
}

export default WebSocketProvider;
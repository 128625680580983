import {FETCH_FAMILY_MEMBERS_FAILURE, FETCH_FAMILY_MEMBERS_SUCCESS} from '../actions/index';

const initialState = {
    family_members: [],
    loading: true,
    error: null
};

export default function familyMembersReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_FAMILY_MEMBERS_SUCCESS:
            // All done: set loading "false".
            // Also, replace the items with the ones from the server
            return {
                ...state,
                loading: false,
                family_members: action.payload.family_members
            };
        case FETCH_FAMILY_MEMBERS_FAILURE:
            // The request failed. It's done. So set loading to "false".
            // Save the error, so we can display it somewhere.
            // Since it failed, we don't have items to display anymore, so set `items` empty.
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                family_members: []
            };
        default:
            return state;
    }
}
import {config, isApp} from "../helpers";

const onesignal_id = config('onesignal');

if (onesignal_id && !isApp()) {
    window.OneSignal = window.OneSignal || [];

    window.OneSignal.push(function() {
        window.OneSignal.init({
            appId: onesignal_id,
        });
    });
}

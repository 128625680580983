import {FETCH_GOAL_FAILURE, FETCH_GOAL_SUCCESS} from '../actions/index';

const initialState = {
    data: {},
    loading: true,
    error: null
};

export default function goalReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_GOAL_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.goal
            };
        case FETCH_GOAL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: {}
            };
        default:
            return state;
    }
}
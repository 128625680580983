import {config} from "./helpers.js";

// SVG icons
import nav_icon from '../assets/images/icons/nav-icon.svg';
import logo from '../assets/images/logo.svg';
import logo_green from '../assets/images/logo-green.svg';
import replace from '../assets/images/icons/replace.svg';
import close_icon from '../assets/images/icons/close.svg';
import share_icon from '../assets/images/icons/share.svg';
import swap from '../assets/images/icons/swap.svg';
import pen_icon from '../assets/images/icons/pen.svg';
import arrow_left from '../assets/images/icons/carousel-prev.svg';
import arrow_right from '../assets/images/icons/carousel-next.svg';
import checkmark from '../assets/images/icons/checkmark.svg';
import search_icon from '../assets/images/icons/search.svg';
import check_circle from '../assets/images/icons/check-circle.svg';
import plus_circle from '../assets/images/icons/plus-circle.svg';
import heart_empty from '../assets/images/icons/heart-empty-black.svg';
import heart from '../assets/images/icons/heart-black.svg';
import log_out from '../assets/images/icons/log-out.svg';
import goals from '../assets/images/icons/goals.svg';
import grocery_list from '../assets/images/icons/grocery-list.svg';
import meal_plan from '../assets/images/icons/meal-plan.svg';
import meal_plan_active from '../assets/images/icons/meal-plan-active.svg';
import shopping_cart_active from '../assets/images/icons/shopping-cart-active.svg';
import chef_active from '../assets/images/icons/chef-active.svg';
import bar_chart_active from '../assets/images/icons/bar-chart-active.svg';
import training from '../assets/images/icons/training.svg';
import ingredients from '../assets/images/icons/ingredients.svg';
import noodles from '../assets/images/icons/noodles.svg';
import dumbbell from '../assets/images/icons/dumbbell.svg';
import hourglass from '../assets/images/icons/hourglass.svg';
import settings from '../assets/images/icons/settings.svg';
import profile from '../assets/images/icons/profile.svg';
import family from '../assets/images/icons/family.svg';
import chef from '../assets/images/icons/chef.svg';
import weight from '../assets/images/icons/weight.svg';
import success from '../assets/images/icons/success.svg';
import plus from '../assets/images/icons/plus.svg';
import minus from '../assets/images/icons/minus.svg';
import trophy from '../assets/images/icons/trophy.svg';
import credit_card from '../assets/images/icons/credit-card.svg';
import bill from '../assets/images/icons/bill.svg';
import leaf from '../assets/images/icons/leaf.svg';
import question_mark from '../assets/images/icons/question-mark.svg';
import star from '../assets/images/icons/star.svg';
import all_recipes from '../assets/images/icons/all-recipes.svg';
import fast_recipes from '../assets/images/icons/fast-recipes.svg';
import simple_recipes from '../assets/images/icons/simple-recipes.svg';
import cheap_recipes from '../assets/images/icons/cheap-recipes.svg';
import key_icon from '../assets/images/icons/key.svg';
import shopping_cart from '../assets/images/icons/shopping-cart.svg';
import phone from '../assets/images/icons/app.svg';
import paper_plane from '../assets/images/icons/paper-plane.svg';
import printer from '../assets/images/icons/printer.svg';
import save from '../assets/images/icons/save.svg';
import clip_board from '../assets/images/icons/clip-board.svg';
import hamburger from '../assets/images/icons/hamburger.svg';
import notes from '../assets/images/icons/notes.svg';
import stopwatch from '../assets/images/icons/stopwatch.svg';
import jump_rope from '../assets/images/icons/jump-rope.svg';
import celebration from '../assets/images/icons/celebration.svg';
import notifications from '../assets/images/icons/notifications.svg';
import notification_default from '../assets/images/icons/notification-default.svg';
import warning from '../assets/images/icons/warning.svg';
import sort from '../assets/images/icons/sort.svg';
import filter from '../assets/images/icons/filter.svg';
import basic_recipes from '../assets/images/icons/basic-recipes.svg';
import paypal from '../assets/images/icons/paypal.svg';
import lock from '../assets/images/icons/lock.svg';
import apple from '../assets/images/icons/apple.svg';
import eaten_apple from '../assets/images/icons/eaten-apple.svg';
import salt from '../assets/images/icons/salt.svg';
import logo_white from '../assets/images/logo-white.svg';
import replace_meal from '../assets/images/icons/replace-meal.svg';
import equal_circle from '../assets/images/icons/equal-circle.svg';
import minus_circle from '../assets/images/icons/minus-circle.svg';
import plus_circle_s from '../assets/images/icons/plus-circle-small.svg';
import nuts from '../assets/images/icons/nuts.svg';
import gluten from '../assets/images/icons/gluten.svg';
import lactose from '../assets/images/icons/lactose.svg';
import shellfish from '../assets/images/icons/shellfish.svg';
import plate from '../assets/images/icons/plate.svg';
import search_v2 from '../assets/images/icons/search_v2.svg';
import apple_v2 from '../assets/images/icons/apple_v2.svg';
import chat_icon from '../assets/images/icons/chat-icon.svg';
import discover from '../assets/images/icons/discover.svg';
import feed from '../assets/images/icons/feed.svg';
import myblog from '../assets/images/icons/myblog.svg';
import comments from '../assets/images/icons/comments.svg';
import comments_active from '../assets/images/icons/comments_active.svg';
import feed_active from '../assets/images/icons/feed_active.svg';
import discover_active from '../assets/images/icons/discover_active.svg';
import comment from '../assets/images/icons/comment.svg';
import fork from '../assets/images/icons/fork.svg';
import user_icon from '../assets/images/icons/user.svg';
import message from '../assets/images/icons/message.svg';
import glass from '../assets/images/icons/glass.svg';
import water from '../assets/images/water.png';
import footprints from '../assets/images/icons/footprints.svg';
import google_fit from '../assets/images/icons/google-fit.png';
import selver_logo from '../assets/images/selver-logo.png';
import barcode from '../assets/images/icons/barcode.svg';
import swipe from '../assets/images/icons/swipe.svg';
import dislike from '../assets/images/icons/dislike.svg';
import diary from '../assets/images/icons/diary.svg';
import diary_active from '../assets/images/icons/diaryactive.png';
import masculine_user from '../assets/images/icons/masculine-user.svg';
import running from '../assets/images/icons/run.svg';
import scale from '../assets/images/icons/scale.svg';

// Images
import blog_overview from '../assets/images/blog-overview.jpg';
import blog_featured_image from '../assets/images/blog-featured-image.jpg';
import shop_sidebar from '../assets/images/banners/shop-sidebar.jpg';

import anything_dish from '../assets/images/onboarding/anything-dish.png';
import pescatariang_dish from '../assets/images/onboarding/pescatarian-dish.png';
import vegetarian_dish from '../assets/images/onboarding/vegetarian-dish.png';
import vegan_dish from '../assets/images/onboarding/vegan-dish.png';

import clock from '../assets/images/daily-plan/clock.png';
import log_workout from '../assets/images/daily-plan/log-workout.jpg';

import meal_bg_1 from '../assets/images/empty-plan/meal-1.jpg';
import meal_bg_2 from '../assets/images/empty-plan/meal-2.jpg';
import meal_bg_4 from '../assets/images/empty-plan/meal-4.jpg';
import meal_bg_8 from '../assets/images/empty-plan/meal-8.jpg';

// SVG images
import female from '../assets/images/female.svg';
import male from '../assets/images/male.svg';

// PNGs
import iphone_en from '../assets/images/iphone-en.png';
import iphone_et from '../assets/images/iphone-et.png';
import iphone_lv from '../assets/images/iphone-lv.png';
import personal_plan from '../assets/images/icons/personal-plan.png';
import simple_groceries from '../assets/images/icons/simple-groceries.png';
import feature_goal from '../assets/images/icons/feature-goal.png';
import home_training from '../assets/images/icons/home-training.png';
import googleplay from '../assets/images/googleplay.png';
import appstore from '../assets/images/appstore.png';
import not_found from '../assets/images/404.png';
import visa from '../assets/images/icons/visa_logo.png';
import mastercard from '../assets/images/icons/mastercard_logo.png';
import app_logo from '../assets/images/icons/app-logo.png';
import undraw_question from '../assets/images/icons/undraw_question.png';
import facebook from '../assets/images/icons/fb-icon.png';
import inactive_banner from '../assets/images/inactive_banner.png';
import macro_energy from '../assets/images/macros/energy-icon.png';
import macro_protein from '../assets/images/macros/protein-icon.png';
import macro_carbs from '../assets/images/macros/carbs-icon.png';
import macro_fat from '../assets/images/macros/fat-icon.png';
import salad_bowl from '../assets/images/salad-bowl.png';
import goal_trophy from '../assets/images/goal-trophy.png';
import info_icon from '../assets/images/icons/info-color.png';
import fitbit from '../assets/images/icons/fitbit.png';
import polar from '../assets/images/icons/polar.png';
import apple_health from '../assets/images/icons/apple-health.png';

// Levels
import level_badge from '../assets/images/levels/level-badge.png';

import apple_logo from '../assets/images/icons/apple-logo.svg';

import level_badge_bronze from "../assets/images/levels/level-badge-bronze.png";
import level_badge_silver from "../assets/images/levels/level-badge-silver.png";
import level_badge_gold from "../assets/images/levels/level-badge-gold.png";
import level_badge_diamond from "../assets/images/levels/level-badge-diamond.png";
import level_badge_max from "../assets/images/levels/level-badge-max.png";

import chef_hat_video from "../assets/videos/hat.mp4";
import salad_video from "../assets/videos/salad.mp4";

// Default eng image
let iphone = iphone_en;

// Conditional image depending on language
if (config('lang') == 'et') {
    iphone = iphone_et;
}

if (config('lang') == 'lv') {
    iphone = iphone_lv;
}

export {
    logo,
    iphone,
    nav_icon,
    personal_plan,
    simple_groceries,
    feature_goal,
    home_training,
    googleplay,
    appstore,
    arrow_left,
    arrow_right,
    checkmark,
    close_icon,
    search_icon,
    share_icon,
    swap,
    check_circle,
    replace,
    plus_circle,
    heart_empty,
    heart,
    blog_overview,
    pen_icon,
    log_out,
    not_found,
    goals,
    meal_plan,
    grocery_list,
    training,
    ingredients,
    dumbbell,
    noodles,
    hourglass,
    settings,
    profile,
    family,
    chef,
    weight,
    success,
    plus,
    minus,
    trophy,
    credit_card,
    bill,
    leaf,
    question_mark,
    blog_featured_image,
    star,
    all_recipes,
    fast_recipes,
    simple_recipes,
    cheap_recipes,
    key_icon,
    shopping_cart,
    phone,
    paper_plane,
    printer,
    save,
    notes,
    clip_board,
    hamburger,
    stopwatch,
    jump_rope,
    female,
    male,
    celebration,
    notifications,
    notification_default,
    warning,
    sort,
    filter,
    basic_recipes,
    paypal,
    lock,
    shop_sidebar,
    apple,
    eaten_apple,
    salt,
    logo_white,
    replace_meal,
    equal_circle,
    minus_circle,
    plus_circle_s,
    logo_green,
    nuts,
    gluten,
    lactose,
    shellfish,
    plate,
    anything_dish,
    pescatariang_dish,
    vegetarian_dish,
    vegan_dish,
    clock,
    search_v2,
    apple_v2,
    log_workout,
    meal_plan_active,
    shopping_cart_active,
    chef_active,
    bar_chart_active,
    chat_icon,
    visa,
    mastercard,
    discover,
    feed,
    myblog,
    comments,
    comments_active,
    feed_active,
    discover_active,
    comment,
    app_logo,
    undraw_question,
    fork,
    facebook,
    inactive_banner,
    apple_logo,
    user_icon,
    message,
    glass,
    water,
    footprints,
    google_fit,
    meal_bg_1,
    meal_bg_2,
    meal_bg_4,
    meal_bg_8,
    barcode,
    selver_logo,
    level_badge,
    swipe,
    dislike,
    diary,
    diary_active,
    macro_energy,
    macro_fat,
    macro_protein,
    macro_carbs,
    level_badge_bronze,
    level_badge_silver,
    level_badge_gold,
    level_badge_diamond,
    level_badge_max,
    chef_hat_video,
    salad_video,
    salad_bowl,
    goal_trophy,
    masculine_user,
    running,
    scale,
    info_icon,
    fitbit,
    polar,
    apple_health
}

import {
    BLOG_FILTER,
    BLOG_KEYWORD,
    FETCH_BLOG_FEED_BEGIN,
    FETCH_BLOG_FEED_FAILURE,
    FETCH_BLOG_FEED_SUCCESS
} from '../actions/index';
import {getCookie} from "../helpers";

const initialState = {
    posts: [],
    meta: {},
    blog_filter: getCookie('blog_filter') ? getCookie('blog_filter') : 'fitlap',
    blog_keyword: '',
    loading: true,
    error: null
};

export default function blogFeedReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_BLOG_FEED_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case FETCH_BLOG_FEED_SUCCESS:
            return {
                ...state,
                loading: false,
                posts: action.payload.feed.meta.current_page > 1 ? [...state.posts, ...action.payload.feed.data] : action.payload.feed.data,
                meta: action.payload.feed.meta
            };
        case FETCH_BLOG_FEED_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                meta: {},
                posts: []
            };
        case BLOG_FILTER:
            return {
                ...state,
                blog_filter: action.payload
            };
        case BLOG_KEYWORD:
            return {
                ...state,
                blog_keyword: action.payload
            };
        default:
            return state;
    }
}

import {FETCH_AUTHORS_FAILURE, FETCH_AUTHORS_SUCCESS} from '../actions/index';

const initialState = {
    data: [],
    loading: true,
    error: null
};

export default function authorsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_AUTHORS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.authors
            };
        case FETCH_AUTHORS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: {}
            };
        default:
            return state;
    }
}
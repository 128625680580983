// userReducer.js
import {
    FETCH_DIARY_FAILURE,
    FETCH_DIARY_SUCCESS,
    FETCH_MENU_FAILURE,
    FETCH_MENU_REPORT,
    FETCH_MENU_SUCCESS,
    UPDATE_MENU_EATEN_KCAL
} from '../actions/index';

const initialState = {
    data: [],
    report: [],
    allergens: [],
    loading: true,
    error: null,
    diary: [],
    loading_diary: true,
    eaten_kcal: 0
};

export default function menuReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_MENU_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.menu
            };
        case FETCH_MENU_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: {}
            };
        case FETCH_DIARY_SUCCESS:
            return {
                ...state,
                loading_diary: false,
                diary: action.payload.diary
            };
        case FETCH_DIARY_FAILURE:
            return {
                ...state,
                loading_diary: false,
                diary: []
            };
        case UPDATE_MENU_EATEN_KCAL:
            return {
                ...state,
                eaten_kcal: action.payload.data
            };
        case FETCH_MENU_REPORT:
            return {
                ...state,
                report: action.payload.report
            };
        default:
            return state;
    }
}
import {config, isApp} from "../helpers";

if (config('facebook.pixel_id')) {
    // eslint-disable-next-line no-unused-expressions
    !function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
    }(window,
        document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    // Init Facebook pixel
    window.fbq('init', config('facebook.pixel_id'));
    window.fbq('track', 'PageView');

    if (isApp()) {
        // numeric ID values are converted to strings, otherwise the API will reject them
        window.fbq('set', 'mobileBridge', '' + config('facebook.pixel_id'), '' + config('facebook.app_id'));
    }
}

import {config} from "../helpers.js";

if (config("delighted_id")) {
    // eslint-disable-next-line no-unused-expressions
    !function (e, t, r, n, a) {
        if (!e[a]) {
            for (var i = e[a] = [], s = 0; s < r.length; s++) {
                var c = r[s];
                i[c] = i[c] || function (e) {
                    return function () {
                        var t = Array.prototype.slice.call(arguments);
                        i.push([e, t])
                    }
                }(c)
            }
            i.SNIPPET_VERSION = "1.0.1";
            var o = t.createElement("script");
            // eslint-disable-next-line no-unused-expressions
            o.type = "text/javascript", o.async = !0, o.src = "https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/" + n + "/" + a + ".js";
            var g = t.getElementsByTagName("script")[0];
            g.parentNode.insertBefore(o, g)
        }
    }(window, document, ["survey", "reset", "config", "init", "set", "get", "event", "identify", "track", "page", "screen", "group", "alias"], config("delighted_id"), "delighted");
}
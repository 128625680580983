import {isImperialSystem} from "../helpers";
import i18next from "i18next";

export const getMinWeight = (unitSystem, familyMember = false) => {
    if (isImperialSystem(unitSystem)) {
        // lbs
        return familyMember ? 22 : 88;
    } else {
        // kg
        return familyMember ? 10 : 40;
    }
}

export const getMaxWeight = (unitSystem, familyMember = false) => {
    if (isImperialSystem(unitSystem)) {
        // lbs
        return 418;
    } else {
        // kg
        return 190;
    }
}

export const getLevelClassname = (value) => {
    if (value < 10) {
        return 'beginner-level';
    }

    if (value < 20) {
        return 'mid-level';
    }

    if (value < 30) {
        return 'high-level';
    }

    if (value < 40) {
        return 'very-high-level';
    }

    if (value > 39) {
        return 'master-level';
    }
}

export const getGreetingsMessage = (user) => {
    let today = new Date();
    let hourNow = today.getHours();
    let greeting;

    if (hourNow < 11) {
        greeting = 'welcome.morning';
    } else if (hourNow < 17) {
        greeting = 'welcome.afternoon';
    } else if (hourNow < 24) {
        greeting = 'welcome.evening'
    } else {
        greeting = 'welcome';
    }

    return i18next.t(greeting, {name: user.name_f})
}

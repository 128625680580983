// userReducer.js
import {FETCH_NOTIFICATIONS_FAILURE, FETCH_NOTIFICATIONS_SUCCESS} from '../actions/index';

const initialState = {
    data: {},
    loading: true,
    error: null
};

export default function notificationsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,                
                loading: false,
                ...action.payload,
            };
        case FETCH_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: {}
            };
        default:
            return state;
    }
}
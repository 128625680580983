// levelsReducer.js
import {FETCH_USER_LEVELS_FAILURE, FETCH_USER_LEVELS_SUCCESS,} from '../actions/index';

const initialState = {
    data: {},
    loading: true,
    error: null,
};

export default function levelsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_USER_LEVELS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.levels
            };
        case FETCH_USER_LEVELS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: {}
            };
        default:
            return state;
    }
}
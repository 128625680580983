var urlParams = new URLSearchParams(window.location.search);
let config =  {
    lang: null, // correct language is selected in i18n.js
    lang_options : ['en', 'fi'] ,
    default_lang: 'fi',
    email: 'info@fitlap.fi',
    amember_signup: 'https://fitlap.fi/amember/signup',
    public_app_url: 'https://fitlap.fi',
    domain: 'fi',
    api: {
        url: 'https://api2.fitlap.fi/',
        version: 'v1',
        websocket: 'websocket.fitlap.in',
        websocket_key: 78945613,
        tracking: 'errors' // none|errors|all
    },
    wordpress: {
        author: 3,
        slugs: {
            terms: 'yksityisyysmaaraykset',
            cookie_policy: 'evasteet',
            terms_of_sale: 'yksityisyysmaaraykset',
            faq: 'ukk',
        }
    },
    logging: {
        key: 'AIzaSyCzaAhDIhgarutR_hxbWEoXS0xdzcd2eNk',
        service: 'app.fitlap.fi',
        version: '3.7'
    },
    analytics_id: 'G-H54RWTHNN3',
    facebook: {
        app_id: 1637253883215633,
        pixel_id: 772789740895265,
        group: 'https://www.facebook.com/groups/fitlap.fi'
    },
    hotjar: 2201117,
    amplitude: '40081728b586b5d053eec1fe4bfd9416',
    delighted_id: "",
    url_translations: {
        et: {
            'recipes' : 'retseptid',
            'blog-post': 'postitus',
            'blog': 'blogi',
            'user': 'kasutaja',
            'signup': 'liitu',
            'signup-email': 'liitu/email',
            'forgot-password': 'unustasin-parooli',
            'testimonials': 'edulood',
            'faq': 'kkk',
            'toc': 'privaatsustingimused',
            'cookies': 'kupsiste-kasutamine',
            'terms-of-sale': 'muugi-ja-kasutustingmused',
            'partners': 'partnerid',
            'contact': 'kontakt',
            'shopping-list-public': 'shopping-list-public'
        },
        en: {
            'recipes' : 'recipes',
            'blog-post': 'blogi',
            'blog': 'blog',
            'user': 'user',
            'signup': 'signup',
            'signup-email': 'signup/email',
            'forgot-password': 'forgot-password',
            'testimonials': 'testimonials',
            'faq': 'faq',
            'toc': 'terms-and-conditions',
            'cookies': 'about-cookies',
            'terms-of-sale': 'terms-of-sale',
            'partners': 'partners',
            'contact': 'contact',
            'shopping-list-public': 'shopping-list-public'
        },
        fi: {
            'recipes' : 'recipes',
            'blog-post': 'blog-post',
            'blog': 'blog',
            'user': 'user',
            'signup': 'signup',
            'signup-email': 'signup/email',
            'forgot-password': 'forgot-password',
            'testimonials': 'asiakkaiden-tarinoita',
            'faq': 'ukk',
            'toc': 'yksityisyysmaaraykset',
            'cookies': 'evasteet',
            'terms-of-sale': 'yksityisyysmaaraykset',
            'partners': 'partners',
            'contact': 'henkilokunta',
            'shopping-list-public': 'shopping-list-public'
        }
    },
    user: {
        male: 1,
        female: 2,
        goal_lose_weight: 1,
        goal_maintain_weight: 2,
        goal_gain_weight: 3,
    },
    unit_system: {
        metric: 1,
        imperial: 2,
        display_amount: 1,
        display_custom_amount: 2,
        display_amount_imperial: 4,
        display_custom_amount_imperial: 8,
    },
    feature: {
        unit_system_enabled: false,
        imperial_ingredients: false,
        banner: 'https://wp.fitlap.fi/wp-json/acf/v3/options/options',
        shop_link: false,
        training_groups: true,
        recipe_price: false,
        hide_youtube_link: true,
        levels: true,
        selver: false,
        logo_feature: false,
        google_login: false,
        facebook_login: true,
    },
    recipe: {
        condiments_category: 8
    },
    free_products: [3, 4, 8, 9],
    placeholder_recipe_id: 1216,
    affiliate: "https://fitlap.fi/amember/aff/go/",
    ingredients_limit_40: [4, 139, 140, 141, 209, 229, 232, 255, 342, 406, 501, 502, 525, 550],
    placeholder_ingredient: 0
};

export default config;

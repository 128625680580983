import {FETCH_WEIGHT_LOG_FAILURE, FETCH_WEIGHT_LOG_SUCCESS} from '../actions/index';

const initialState = {
    data: [],
    loading: true,
    error: null
};

export default function weightsReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_WEIGHT_LOG_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.weight_log
            };
        case FETCH_WEIGHT_LOG_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                data: {}
            };
        default:
            return state;
    }
}